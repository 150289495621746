<template>
  <div class="router_view">
    <router-view v-if="isShowApp" v-slot="{ Component, route }">
      <transition-group :name="route.meta ? route.meta.transitionName : ''">
        <div key="1">
          <keep-alive :include="storeData.keepAliveInclude">
            <component :key="route.name" v-if="route.meta.keepAlive" :is="Component" @vue:mounted="componentOnMounted" />
          </keep-alive>
        </div>
        <component :key="route.name" v-if="!route.meta.keepAlive" :is="Component" @vue:mounted="componentOnMounted" />
      </transition-group>
    </router-view>
    <GlobalLoading></GlobalLoading>
    <GlobalBackToIndex v-if="!isApp() && !['ysfMessage', 'videoVcPage', 'shareVideo'].includes($route.name)"></GlobalBackToIndex>
    <GlobalBackToGrowth v-if="!isApp() && !['ysfMessage', 'videoVcPage', 'shareVideo'].includes($route.name)"></GlobalBackToGrowth>
    <GlobalTabbar v-if="$route.meta.TabBarShow"></GlobalTabbar>
    <component :is="asyncComponent" />
    <!-- 打开app -->
    <openAppPopup></openAppPopup>
  </div>
</template>

<script setup>
import { onMounted, ref, provide, defineAsyncComponent, watch } from 'vue-demi'
import { setUserConfig } from '@umengfe/apm'
import dayjs from 'dayjs'
import { store } from '@/pinia/store'
import { req } from '@/api'
import { codeList } from '@/api/codeList'
import { wyAuthService } from '@/tsApi/service'
import { initAMap } from '@/hooks/useAMap.js'
import {
  getCID,
  getUrlData,
  resetArr,
  isWeiXin,
  isApp,
  checkAppHandle,
  isIos,
  checkIsNewPackage,
  isAndroid,
  getUrlChannelCode,
  isJwApp,
  jwAndroidApp,
  getServiceTel,
  checkIsInvite,
} from '@/utils'
import { isNotNeedInit, isNotLocationInit } from '@/hooks/useNotNeedInit'
import { useRouter } from 'vue-router'
import GlobalLoading from '@/components/GlobalLoading.vue'
import GlobalTabbar from '@/components/GlobalTabbar.vue'
import GlobalBackToIndex from '@/components/GlobalBackToIndex.vue'
import GlobalBackToGrowth from '@/components/GlobalBackToGrowth.vue'
import { hideLoading, showLoading } from '@/hooks/useVant'
import openAppPopup from '@/views/homeModule/components/openAppPopup.vue'
import { sendDataToApp } from '@/hooks/useBridge'
import { onLCP, onFCP, onFID, onCLS } from 'web-vitals'
import { defaultAddress } from '@/hooks/useAMap'

const router = useRouter()
const isShowApp = ref(false)
const storeData = store()
const isShowOpenAppPopup = ref(false) //外部浏览器打开或安装app
const asyncComponent = ref(null)

onMounted(async () => {
  if (isNotNeedInit()) {
    hideLoading()
    isShowApp.value = true
    return
  }
  showLoading()
  isShowApp.value = false
  storeData.isCloseOnOrderGoing = true

  if (getUrlData('channelCode') === 'jw') {
    localStorage.setItem('isJwApp', '1')
  }

  console.log('初始化开始当前时间', new Date().getTime())
  if (isApp()) {
    console.log('我是app', isApp())
    // H5提前注册桥
    try {
      window.updHeaders = callUpdHeaders
    } catch (e) {
      console.log('调用js失败', e)
    }
    // APP模式且非特殊页面情况下，需要走桥接方法拿数据
    try {
      await appBridgeInit()
      if ((await checkAppHandle('iphoneStatusBarHeight')) && isIos()) {
        await getPhoneStatusBarHeight()
      }
      if (!localStorage.getItem('levelIconList')) {
        await getLevelIcon()
      }
    } catch (error) {
      console.log('桥接失败', error)
    }
  } else {
    storeData.isShowNewSource = true
    // 非APP模式(微信环境)走常规页面初始化
    await wxHeaderInfoInit()
    if (!isNotLocationInit()) {
      //非APP模式(微信环境)且非特殊页面情况下，正常走定位等初始化
      await locationInit()
      isShowApp.value = true
      await getCouponsActivity() //获取是否展示天天优惠悬浮框
    }
    if (!localStorage.getItem('levelIconList') && router.currentRoute.value.path !== '/pages/sharepay/index') {
      await getLevelIcon()
    }
    adCodeInit()
    if (storeData.appData.userId && !isApp() && !['inviteePage', 'ysfMessage', 'invitePoster'].includes(router.currentRoute.value.name)) {
      addGlobalVoiceCall()
    }
    if (!isWeiXin() && !['videoVcPage', 'shareVideo'].includes(router.currentRoute.value.name) && !isJwApp()) {
      isShowOpenAppPopup.value = true
    }

    if (jwAndroidApp()) {
      //0：默认，1：华为， 2：应用宝， 3：小米， 4：oppo， 5：vivo  6：三星
      sendDataToApp('fromAndroidChannel', null, (x) => {
        storeData.androidChannel = x
      })
    }
    onLCP(console.log)
    onCLS(console.log)
    onFID(console.log)
    onFCP(console.log)
  }

  console.log('初始化完成当前时间', new Date().getTime())
  isShowApp.value = true

  await initDict()
  initBucket()

  hideLoading()
})

const initBucket = async () => {
  const bucketKey = dayjs().format('bucket-YYYYMMDD')
  if (localStorage.getItem(bucketKey)) {
    storeData.bucket = localStorage.getItem(bucketKey)
    return
  }
  const res = await wyAuthService.cidBucket().catch(() => {})
  if (res.code === 0) {
    storeData.bucket = res.data
  }
}

watch(
  () => storeData.appData.userId,
  (n) => {
    console.log('监听userId')
    if (n && !isApp() && router.currentRoute.value.name !== 'ysfMessage') {
      addGlobalVoiceCall()
    }
  },
)

const addGlobalVoiceCall = () => {
  setTimeout(async () => {
    try {
      asyncComponent.value = defineAsyncComponent(() => import('./components/GlobalImVoiceCall.vue'))
      let imFunc = await import('@/hooks/useIm')
      await imFunc.TUIKitInit()
      if (await imFunc.TUIKitLogin()) {
        await imFunc.TUICallInit()
      }
    } catch (e) {
      console.log('初始化语音通话失败', e)
    }
  }, 3000)
}

const adCodeInit = () => {
  // 广告商推广id等参数初始化
  const pid = getUrlData('pid')
  const tid = getUrlData('tid')
  if (pid) {
    localStorage.setItem('pid', pid)
  }
  if (tid) {
    localStorage.setItem('tid', tid)
  }
}

const wxHeaderInfoInit = async () => {
  // 微信公众号模式下，初始化appData
  try {
    const channelCode = getUrlChannelCode()
    storeData.urlChannelCode = getUrlChannelCode()
    const localChannelCode = localStorage.getItem('channelCode')
    const latestChannelCode = localStorage.getItem('latestChannelCode')
    let utms = ''
    // 当前渠道码使用逻辑如下：(utms传值格式为 ’wyh5,xxx‘或者’wyh5‘)
    // 1、首次进入：如果没有渠道码，则默认赋值’wyh5‘，如果url中有渠道码，则给utms赋值该渠道码；
    // 2、第二次以及第n次进入：如果url携带了渠道码，则给utms的第二位赋值相应的渠道码，如果url中没有渠道码，则使用历史缓存的第二位渠道码，若历史中也没有则不赋值。有则替换，无则使用上次的；
    // 3、由于往约跟汗滴公用一个域名，所以针对utms中第一位渠道码如果是’hdh5‘的则手动改成’wyh5‘。
    if (channelCode) {
      // 首先看本地是否有缓存过
      if (localChannelCode) {
        // 校验是否是拉新分享的渠道码，此时只更新拉新utms，即utms的第一位
        if (checkIsInvite(channelCode)) {
          utms = channelCode
          if (latestChannelCode) {
            utms += ',' + latestChannelCode
          }
          localStorage.setItem('channelCode', channelCode)
        } else {
          utms = (localChannelCode === 'hdh5' ? 'wyh5' : localChannelCode) + ',' + channelCode
          localStorage.setItem('latestChannelCode', channelCode)
        }
      } else {
        localStorage.setItem('channelCode', channelCode)
        utms = channelCode
      }
    } else {
      if (localChannelCode) {
        utms = localChannelCode === 'hdh5' ? 'wyh5' : localChannelCode
        if (latestChannelCode) {
          utms += ',' + latestChannelCode
        }
      } else {
        localStorage.setItem('channelCode', 'wyh5')
        utms = 'wyh5'
      }
    }
    let meta = {
      os: 7, //公众号
      lang: 'zh-CN',
      utms: utms,
    }
    const cid = getCID()
    storeData.headerInfo['c-id'] = `h-${cid}`
    storeData.headerInfo['c-meta'] = JSON.stringify(meta)
    storeData.appData.os = isWeiXin() ? 104006 : 104007
    storeData.isNeedRefreshTopicList = true
  } catch (error) {
    console.log('wxHeaderInfoInit失败', error)
  }
}

//所有字典初始化
const initDict = async () => {
  const res = await req.getSelectedDict({ codes: codeList })
  if (res.code === 0 && res.data.length > 0) {
    resetArr(storeData.allDict, res.data)
  }
  getServiceTel()
  isShowApp.value = true
  hideLoading()
}

//是否展示天天优惠
const getCouponsActivity = async () => {
  let popParams = {
    cityCode: storeData.locationData.cityCode,
    latitude: storeData.locationData.latitude,
    longitude: storeData.locationData.longitude,
    os: storeData.appData.os || '104006',
    pid: localStorage.getItem('pid'),
    tid: localStorage.getItem('tid'),
    channelCode: localStorage.getItem('channelCode'),
    platformType: 0,
  }
  const res = await req.homePageSetting({ ...popParams })
  // storeData.dynamicShow = res.data.dynamicShow
  storeData.productBuyCountShow = res.data.productBuyCountShow
  storeData.unlikeIdList.techIds = res.data.unlikeIdList.techIds || []
  storeData.unlikeIdList.ignoreShowTechIds = res.data.unlikeIdList.ignoreShowTechIds || []
  storeData.isShowCouponsActivity = res.data.isShowCouponsActivity === 1
}
//定位
const locationInit = async () => {
  if (!storeData.locationData.cityCode) {
    let result = await initAMap(3)
    console.log(69969666, result)
    if (!result?.cityCode) {
      result = defaultAddress
    }
    storeData.locationData.cityName = result.cityName
    storeData.locationData.cityCode = result.cityCode
    storeData.locationData.adcode = result.adcode
    storeData.locationData.longitude = result.longitude
    storeData.locationData.latitude = result.latitude
    storeData.locationData.position = result.position
    storeData.locationData.locationAddress = result.locationAddress
    storeData.locationData.formattedAddress = result.regeocode.formattedAddress
  }
}
//获取用户等级图标
const getLevelIcon = async () => {
  const res = await req.getConfig()
  localStorage.setItem('levelIconList', res.data.value)
}

//app初始化
const appBridgeInit = () => {
  return new Promise((resolve) => {
    sendDataToApp('getUserToken', null, async (result) => {
      let res = result
      console.log('拿到app数据', res)
      if (isAndroid()) {
        res = JSON.parse(res)
        //0：默认，1：华为， 2：应用宝， 3：小米， 4：oppo， 5：vivo  6：三星
        sendDataToApp('fromAndroidChannel', null, (x) => {
          storeData.androidChannel = x
        })
      }
      storeData.appData.cityName = res.cityName
      storeData.appData.cityCode = res.cityCode
      storeData.appData.adcode = res.adcode
      storeData.appData.cityAreaCode = res.cityAreaCode
      storeData.appData.lat = res.lat
      storeData.appData.lng = res.lng
      storeData.appData.os = res.os
      storeData.appData.userId = res.userId
      storeData.appData.version = res?.version || ''
      if (res.userId) {
        const puid = `uid-${res.userId}`
        setUserConfig({ puid })
      }

      storeData.locationData.cityName = res.cityName
      storeData.locationData.cityCode = res.cityCode
      storeData.locationData.longitude = res.lng
      storeData.locationData.latitude = res.lat

      storeData.appData.os = isIos() ? '104002' : '104001'

      const info = res
      let metaInfo = isIos() ? info.cmeta : JSON.parse(info.cmeta)
      metaInfo['os'] = isIos() ? (metaInfo?.os === 9 ? metaInfo?.os : 9) : metaInfo?.os === 8 ? metaInfo?.os : 8
      storeData.headerInfo['c-meta'] = JSON.stringify(metaInfo)
      if (info.cid) {
        storeData.headerInfo['c-id'] = `h-${info.cid}`
      } else {
        const cid = getCID()
        storeData.headerInfo['c-id'] = `h-${cid}`
      }
      if (await checkIsNewPackage()) {
        console.log('我是新包')
        storeData.isShowNewSource = true
      } else {
        console.log('我是老包')
        storeData.appData.secret = res.secret
        storeData.appData.session = res.session
        storeData.appData.tecName = res.tecName
        storeData.appData.tecAvator = res.tecAvator
        storeData.appData.tecBirthday = res.birthday
        storeData.appData.tecPhone = res.phone
        storeData.appData.tecZodiac = res.zodiac
        storeData.appData.tecSex = res.sex
        if (!localStorage.getItem('expiresAt') || parseInt(localStorage.getItem('expiresAt')) * 1000 - new Date().getTime() <= 10000) {
          console.log('app调用刷新接口')
          let tsApi = await import('@/tsApi/service.ts')
          console.log(tsApi)
          try {
            await tsApi.resetWyExpiresAt()
          } catch (e) {}
        }
        storeData.isShowNewSource = false
      }
      if (await checkAppHandle('webviewRequestMethod')) {
        localStorage.setItem('isGoAppRequest', '1')
      }
      console.log('查看storeData', storeData)
      resolve(true)
    })
  })
}

// 获取顶部状态栏高度
const getPhoneStatusBarHeight = () => {
  return new Promise((resolve) => {
    try {
      sendDataToApp('iphoneStatusBarHeight', '', (res) => {
        console.log('状态栏高度', res)
        storeData.phoneStatusBarHeight = parseFloat(res.toString())
        resolve(true)
      })
    } catch (e) {
      resolve(true)
    }
  })
}

// 更新header
const callUpdHeaders = (val) => {
  if (val) {
    storeData.headerInfo['c-meta'] = val
  }
}

const componentOnMounted = () => {
  const skeletonDom = document.querySelector('#app-skeleton')
  const styleDom = skeletonDom.querySelector('style')
  if (styleDom) {
    skeletonDom.removeChild(styleDom)
  }
  skeletonDom.style.display = 'none'
  document.querySelector('#app').style.display = ''
}

provide('isShowOpenAppPopup', isShowOpenAppPopup)
</script>

<style lang="scss">
@import './assets/style/style.scss';
@import './assets/iconfont/iconfont.css';
@import './assets/iconfont/font.css';
</style>
